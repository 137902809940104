require(['jquery'], function ($) {
    if (location.pathname.includes('/user/register')) {
        $('body #root-wrapper form#usre_rego .fieldset>.form-list>li').each(function () {
            if ($(' > *', this).length === 1) {
                $(this).addClass('single_field');

                if ($(' > .field-container-captcha', this).length) {
                    $(this).addClass('captcha-item');
                }
            }
        });
    }
});