require(['jquery'], function ($) {
    /// Dektop Cart Column ///
    // remove "Cart Subtotal" text from header

    const cartTotal = $(".cart-column #mini-cart .hide-below-960 .cart-total").text().trim();
    $(".cart-column #mini-cart .hide-below-960").html('<span class="cart-total">' + cartTotal + '</span>');

    // add favourites icon to user links
    setTimeout(() => {
        $('header#header .main-header-container .quick-order').parent().before('<li><a href="/user/favourites" rel="nofollow" class="link-favourites" title="Favourites">Favourites</a></li>');
        $('.isPublic .cart-column #mini-cart .feature-icon-hover > a').attr('href', '/user/login/');
    }, 500);
});